// extracted by mini-css-extract-plugin
export var recentArticles = "cH_tS";
export var recentArticles__link = "cH_tV";
export var recentArticles__post = "cH_tT";
export var recentArticles__postAuthor = "cH_t2";
export var recentArticles__postAuthorImage = "cH_t1";
export var recentArticles__postAuthorName = "cH_t3";
export var recentArticles__postCategory = "cH_tX";
export var recentArticles__postDate = "cH_t6";
export var recentArticles__postDescriprion = "cH_tZ";
export var recentArticles__postImage = "cH_tW";
export var recentArticles__postInfo = "cH_t4";
export var recentArticles__postInfoWrapper = "cH_t0";
export var recentArticles__postStats = "cH_t5";
export var recentArticles__postTitle = "cH_tY";
export var recentArticles__title = "cH_t7";